import { useEffect, useState } from 'react'
import useFully from './use-fully'

const useLeds = () => {
  const [ledOn, setLedOn] = useState(null)
  const fully = useFully()
  const ledPath = '/sys/devices/platform/led_con_h/zigbee_reset'

  const colourCodes = {
    red: '0x04',
    green: '0x05',
    blue: '0x06',
    purple: '0x16',
    yellow: '0x14',
    orange: '0x10',
  }

  const onOffCommands = {
    on: 0x03,
    off: 0x02,
  }

  // set initial state of leds on load
  useEffect(() => {
    turnOff()
  }, [])

  const sendCode = (code) => {
    let codes = Array.isArray(code) ? code : [code]

    // send command - chain together on one line if multiple codes
    const fullyVersion = fully?.getFullyVersionCode()
    console.log('fully version', fullyVersion, fully?.getFullyVersion())
    fully?.runAdbCommand(
      codes.map((value) => `(echo w ${value} > ${ledPath})`).join(' && ')
    )

    // set status if we turned the led or on off
    if (codes.includes(onOffCommands.on)) setLedOn(true)
    if (codes.includes(onOffCommands.off)) setLedOn(false)
  }

  const turnOff = () => {
    sendCode(onOffCommands.off)
  }

  const turnOn = () => {
    sendCode(onOffCommands.on)
  }

  const setColour = (colour) => {
    if (!ledOn) {
      sendCode([onOffCommands.on, colourCodes[colour]])
    } else {
      sendCode(colourCodes[colour])
    }
  }

  const status = () => {
    return ledOn
  }

  return {
    turnOff,
    turnOn,
    setColour,
    status,
  }
}

export default useLeds
