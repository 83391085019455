import React, { useEffect, useRef, useState } from 'react'
import './App.css'
import logo from './orri-logo-2022-papaya.png'
import preval from 'preval.macro'
import orderBy from 'lodash/orderBy'
import useFully from './hooks/use-fully'
import useLeds from './hooks/use-leds'

const FLAG_READER_NFC_A = 0x01
const FLAG_READER_NO_PLATFORM_SOUNDS = 0x100
const NFC_DETECTED = 'nfcDetected'

const NAMES = {
  '041B5912046881': {
    text: 'Hi Iain, welcome to Wimpole Street!',
    success: true,
  },
  '3|ZbqVxVECQ41TeIQ4|79301701737421|Iain': {
    text:
      'Hi Lucy, welcome to Wimpole Street, ' +
      `today you are in Green stream with your first session at 10 o'clock.`,
    success: true,
  },
  '041A677A9F7080': {
    text:
      "Good morning. We don't have that card on file. " +
      'Please follow the instuctions on the screen to link it with your account',
    success: false,
  },
}

const App = () => {
  const fully = useFully()
  const leds = useLeds()
  const stateRef = useRef({})
  const [chars, setChars] = useState('')
  const [serialNumbers, setSerialNumbers] = useState([])

  useEffect(() => {
    stateRef.current = { chars: chars, serialNumbers: serialNumbers }
  }, [chars, serialNumbers])

  const buildDate = preval`module.exports = new Date();`

  const addNewCard = (serialNumber) => {
    console.log(`Adding ${serialNumber} to currentState`, stateRef.current)
    setSerialNumbers((existingNumbers) => [
      ...existingNumbers,
      {
        timestamp: new Date(),
        serial: serialNumber,
      },
    ])
  }

  const handleCallback = (props) => {
    fully?.showToast(`Callback: ${JSON.stringify(props)}`)
  }

  window.handleCallback = handleCallback

  useEffect(() => {
    const started = fully?.nfcScanStart(
      FLAG_READER_NFC_A | FLAG_READER_NO_PLATFORM_SOUNDS,
      250
    )
    console.log('NFC reader started?', started)
    fully?.bind('onNfcTagDiscovered', 'handleNFC("$serial");')

    return () => {
      started && fully?.nfcScanStop()
    }
  }, [fully])

  const handleNFC = (serialNumber) => {
    window.dispatchEvent(
      new CustomEvent(NFC_DETECTED, { detail: serialNumber })
    )
  }

  window.handleNFC = handleNFC

  const handleNFCDetected = (e) => {
    console.log(`NFC event with serial: "${e.detail}"`)
    addNewCard(e.detail.toUpperCase())
  }

  useEffect(() => {
    window.addEventListener(NFC_DETECTED, handleNFCDetected)
    return () => window.removeEventListener(NFC_DETECTED, handleNFCDetected)
  }, [])

  useEffect(() => {
    if (serialNumbers && serialNumbers.length > 0) {
      const earliestSerial = orderBy(serialNumbers, ['timestamp'])[0]
      console.log(
        `Pulling serial off array`,
        earliestSerial,
        NAMES[earliestSerial.serial]
      )
      const text = NAMES[earliestSerial.serial]
      if (text) {
        if (text.success) {
          leds.setColour('green')
          fully?.playSound('file:///sdcard/Download/success.mp3', false)
        } else {
          leds.setColour('red')
          fully?.playSound('file:///sdcard/Download/wrong-answer.mp3', false)
        }
        setTimeout(() => {
          leds.turnOff()
        }, 3000)
      } else {
        fully?.showToast(`Unknown card: ${JSON.stringify(earliestSerial.serial)}`)
      }

      setSerialNumbers((existingNumbers) =>
        existingNumbers.filter((s) => s !== earliestSerial)
      )
    }
  }, [serialNumbers])

  const handleKeyDown = (event) => {
    const { key } = event
    const { chars } = stateRef.current
    const newChars = `${chars}${key}`
    if (key === 'Enter') {
      console.log(`Adding: ${chars}`)
      addNewCard(chars)
      setChars(() => '')
    } else {
      console.log(`Setting: ${newChars}`)
      if (key.length === 1) setChars(newChars)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  const ethernetMacAddress = fully?.getMacAddressForInterface('eth0')
  const serialNumber = fully?.getSerialNumber()

  const buttons = [
    { label: 'off', command: () => leds.turnOff() },
    { label: 'on', command: () => leds.turnOn() },
    { label: 'red', command: () => leds.setColour('red') },
    { label: 'green', command: () => leds.setColour('green') },
    { label: 'blue', command: () => leds.setColour('blue') },
    { label: 'purple', command: () => leds.setColour('purple') },
    { label: 'yellow', command: () => leds.setColour('yellow') },
    { label: 'orange', command: () => leds.setColour('orange') },
  ]

  const event = {
    subject: 'Copper : Body & Mind',
    teamMember: 'Vicky Beaumont',
    colour: '#AF7A6D',
    time: '11:45 - 12:45',
  }

  return (
    <div className="p-6 flex flex-col bg-brand-marshmallow h-screen w-screen">
      <div className="flex items-center">
        <img className="w-32" src={logo} alt="Orri logo papaya coloured" />
        <div className="ml-auto text-3xl font-ivy text-brand-papaya">
          Tesk Kiosk
        </div>
      </div>
      <div
        className={`mt-6 flex flex-col items-start flex-grow-1 p-6 rounded-2xl h-full flex-grow-1`}
        style={{ backgroundColor: event.colour }}
      >
        <div className="font-jakarta text-3xl font-bold">
          {ethernetMacAddress}
        </div>
        <div className="font-ivy" style={{ fontSize: '5rem' }}>
          {serialNumber}
        </div>
        <div className="p-3 font-semibold">
          LED {leds.status() ? 'ON' : 'OFF'}
        </div>
        <div className="flex flex-wrap">
          {buttons.map((button, key) => (
            <button
              className="rounded px-2 py-1 bg-brand-charcoal text-white m-2 hover:bg-brand-charcoal-light"
              onClick={() => button.command()}
              key={key}
            >
              {button.label}
            </button>
          ))}
        </div>
        <div className="font-mono mt-auto">
          Build Date: {new Date(buildDate).toLocaleString()}
        </div>
      </div>
    </div>
  )
}

export default App
